<template>
  <div id="app">
    <div class="wrapper">
      <Header v-if="$route.meta.enableLayout" />
      <main class="main-wrapper row mb-5">
        <Sidebar class="col-2" v-if="$route.meta.enableLayout" />
        <div class="content col-10">
          <router-view />
        </div>
      </main>
      <Footer v-if="$route.meta.enableLayout && isVisible" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: 'App',
  components: {
    Header,
    Sidebar,
    Footer,
  },
  computed: {
    isVisible() {
      return +process.env.VUE_APP_FOOTER;
    },
    language() {
      return this.$store.getters.language;
    },
  },
  watch: {
    language: {
      handler(newValue) {
        this.$i18n.locale = newValue;
      },
      immediate: true,
    },
  },
  mounted() {
    if (
      !this.$store.getters.notifictions
      && this.$store.getters.isAuthenticated
    ) {
      this.$store.dispatch('notificationsRequest');
    }
  },
};
</script>

<style lang="scss"></style>
