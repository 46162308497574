export default {
  computed: {
    incomeModel() {
      return {
        complex: {
          type: 'complex',
          width: 160,
          detailedQuery: true,
          options: [
            {
              label: this.$t('main.platform'),
              value: {
                type: 'link',
                field: 'url',
                group: 'host',
                detailedKey: 'id',
              },
            },
            {
              label: this.$t('common.country'),
              value: {
                type: 'country',
                field: 'country',
                group: 'country',
                setNestedGroup: 'host',
              },
            },
          ],
        },
        ctr: {
          type: 'common',
          label: 'CTR, %',
          width: 75,
          hiddenForGroup: 'country',
          sortable: true,
        },
        loads: {
          type: 'common',
          label: this.$t('common.downloads'),
          width: 90,
          sortable: true,
        },
        bids: {
          type: 'common',
          label: this.$t('main.credits'),
          width: 80,
          sortable: true,
        },
        efficiency: {
          type: 'common',
          label: this.$t('main.disposalPercent'),
          width: 120,
          sortable: true,
        },
        completed: {
          type: 'common',
          label: this.$t('main.inspectionsPercent'),
          width: 110,
          hiddenForGroup: 'country',
          sortable: true,
        },
        visibility: {
          type: 'common',
          label: this.$t('main.visibilityPercent'),
          width: 120,
          hiddenForGroup: 'country',
          sortable: true,
        },
      };
    },
  },
};
