import store from '../../store';

const ifAuthenticated = async (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next('login');
    return;
  }

  const isRulesAccepted = await (new Promise((resolve) => {
    if (store.getters.getRecentRulesAccepted === null) {
      store.dispatch('authDataRequest', {
        action: 'me',
      }).then((resp) => {
        store.commit('setRecentRulesAccepted', resp.recent_rules_accepted);
        resolve(store.getters.getRecentRulesAccepted);
      });
    } else {
      resolve(store.getters.getRecentRulesAccepted);
    }
  }));

  if (process.env.VUE_APP_MODE_NAME === 'production' && !isRulesAccepted && to.name !== 'important-updates') {
    next('important-updates');
    return;
  }

  next();
};

export default ifAuthenticated;
