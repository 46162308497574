<template>
  <div class="payout-dashboard">
    <div class="row payout-dashboard__money-section pt-2" v-if="data">
      <div class="col-7 pl-3">
        <div class="payout-dashboard__label">{{ $t("main.forPayment") }}</div>
        <div class="pt-2 pb-1">
          <span class="payout-dashboard__amount currency">
            {{ formatNumberCompact(data.on_payment) }}
          </span>
        </div>
        <div class="container-1">
          <div class="row">
            <div class="col-8 pr-0">
              <a
                v-if="false"
                class="payout-dashboard__button"
                href="javascript:void(0)"
                >{{ $t("main.requestForPayment") }}</a
              >
            </div>
            <div class="col-4 pl-1">
              <router-link
                v-if="showWithdrawalMethods"
                :to="{ name: 'help', query: { active: 4 } }"
              >{{
                $t("main.withdrawalMethods")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 pl-0 pr-4">
        <div class="payout-dashboard__label">{{ $t("main.balance") }}</div>
        <div class="pt-2 pb-1">
          <span class="payout-dashboard__balance currency">
            {{ formatNumberCompact(data.balance) }}
          </span>
        </div>
        <div class="payout-dashboard__balance-tip py-1">
          {{ $t("main.amountAvailableAfterVerification") }}
        </div>
      </div>
    </div>
    <div
      class="row payout-dashboard__stat-section"
      v-if="data && data.chart.length"
    >
      <div class="col-7">
        <apexchart
          type="line"
          height="50"
          :options="chartOptions"
          :series="chartDataFormatter('cost', data.chart)"
        />
        <section class="row payout-dashboard__last-3-mo">
          <div v-for="ts in monthsData" :key="ts.ts_month" class="col">
            <span>{{ moment(ts.ts_month).format("MMMM") }}</span>
            <p>{{ ts.cost }}</p>
          </div>
        </section>
      </div>
      <div class="col-5 pl-0 payout-dashboard__stat-links">
        <section class="payout-dashboard__stat-links-inner">
          <div>
            <strong>{{ $t("main.fullStatistics") }}</strong>
          </div>
          <div>
            <a @click="goToStats('day')" href="javascript:void(0)">
              {{ $t("calendar.perDay") }}
            </a>
            <a @click="goToStats('week')" href="javascript:void(0)">
              {{ $t("calendar.inWeek") }}
            </a>
            <a @click="goToStats('month')" href="javascript:void(0)">
              {{ $t("calendar.perMonth") }}
            </a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import chartOptions from '@/configs/chart-options/payoutsIncomeChart';
import customPeriodOptions from '@/configs/customPeriodOptions';
import formatNumberCompact from '@/utils/numberFormat';

moment.locale('ru');

export default {
  name: 'Payout',
  mixins: [customPeriodOptions],
  props: {
    data: {},
  },
  data() {
    return {
      chartOptions,
    };
  },
  computed: {
    monthsData() {
      const reversed = [...this.data.month];
      return reversed.reverse();
    },
    showWithdrawalMethods() {
      return process.env.VUE_APP_MODE_NAME === 'production';
    },
  },
  methods: {
    moment,
    formatNumberCompact,
    chartDataFormatter(name, chartData) {
      const reducer = (acc, cur) => [...acc, cur[name]];
      const data = chartData.reduce(reducer, []);
      return [{ name, data }];
    },
    goToStats(period) {
      const selectedRange = this.customPeriodOptions.find(
        el => el.id === period,
      );
      this.$store.dispatch('setSelectedRange', {
        ...selectedRange,
        shouldReload: true,
      });
      this.$router.push({ name: 'stats' });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";
.payout-dashboard {
  padding: 0 3px;
  &__money-section {
    padding-bottom: 20px;
  }

  &__stat-section {
    border-top: 1px solid $c-button-common;
    padding: 20px 0 0;
  }

  &__last-3-mo {
    padding: 15px 0 0;
    font-size: 0.87rem;

    span {
      color: $c-info;
    }

    p {
      padding: 5px 0;
    }

    .col {
      padding-right: 0;
    }
  }

  &__label {
    color: $c-text-primary;
  }

  &__balance-tip {
    color: $c-text-dimmed;
  }

  &__button {
    display: inline-block;
    padding: 7px 12px;
    color: $c-white;
    text-decoration: none;
    background-color: $c-warning;
    border-radius: 3px;

    &:hover:not(:active) {
      box-shadow: 0px 4px 10px rgba(4, 72, 122, 0.1);
    }
  }

  .currency {
    display: inline-block;
    position: relative;

    &:after {
      content: "₽";
      position: absolute;
      top: 0;
      left: 100%;
      margin-left: 3px;
      font-size: 16pt;
      font-weight: 400;
      line-height: 1.1;
    }
  }

  &__balance {
    font-size: 2rem;
  }

  &__amount {
    font-size: 47px;
    font-weight: 500;
    color: $c-text-primary;

    &.currency:after {
      margin-top: 3px;
      font-size: 1.8rem;
    }
  }

  &__tooltip {
    position: relative;

    &--with-currency {
      padding-right: 11px;

      &:after {
        content: "₽";
        position: absolute;
        right: 0;
      }
    }
  }

  &__stat-links {
    display: flex;
    align-items: center;
    line-height: 1.5;

    strong {
      display: inline-block;
      font-weight: 500;
    }

    a {
      display: inline-block;
      padding-right: 10px;
    }

    &-inner {
      padding-top: 10px;
    }
  }
}

.apexcharts-tooltip.light.apexcharts-tooltip.light {
  color: $c-black;
  border: none;
  box-shadow: none;
}
</style>
